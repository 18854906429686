$custom-blue-palette: (
  50: #ffffff,
  100: #e3f4fb,
  200: #c6e9f8,
  300: #aadef4,
  400: #8ed3f0,
  500: #71c8ed,
  600: #55bde9,
  700: #39b2e5,
  800: #1ca7e2,
  900: var(--main-color),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
  ),
);

$app-light-gray: #fafafa;

.orange {
  color: orange;
}

.green {
  color: green;
}

.red {
  color: red;
}

.white {
  color: white;
}

/// ===================================================
/// HELPER MARGIN AND PADDING FUNCTIONS - SPACING
/// ===================================================

$sides: (
  t: top,
  l: left,
  b: bottom,
  r: right,
);
$values: (5, 7, 10, 15, 20, 25, 30, 35, 40, 50, 60, 80, 500, 900);
$breakpoints: (
  md: 768px,
  xl: 1200px,
  xxl: 1680px,
);

@each $val in $values {
  .gap-#{$val} {
    gap: #{$val}px;
  }
  .p-#{$val} {
    padding: #{$val}px !important;
  }
  @each $bp-name, $bp-value in $breakpoints {
    .p-#{$bp-name}-#{$val} {
      @media (min-width: #{$bp-value}) {
        padding: #{$val}px !important;
      }
    }
  }
  @each $letter, $side in $sides {
    .p#{$letter}-#{$val} {
      padding-#{$side}: #{$val}px !important;
    }
    @each $bp-name, $bp-value in $breakpoints {
      .p#{$letter}-#{$bp-name}-#{$val} {
        @media (min-width: #{$bp-value}) {
          padding-#{$side}: #{$val}px !important;
        }
      }
    }
  }
}

@each $val in $values {
  .m-#{$val} {
    margin: #{$val}px !important;
  }
  @each $bp-name, $bp-value in $breakpoints {
    .m-#{$bp-name}-#{$val} {
      @media (min-width: #{$bp-value}) {
        margin: #{$val}px !important;
      }
    }
  }
  @each $letter, $side in $sides {
    .m#{$letter}-#{$val} {
      margin-#{$side}: #{$val}px !important;
    }
    @each $bp-name, $bp-value in $breakpoints {
      .m#{$letter}-#{$bp-name}-#{$val} {
        @media (min-width: #{$bp-value}) {
          margin-#{$side}: #{$val}px !important;
        }
      }
    }
  }
}

@each $val in $values {
  .h-#{$val} {
    height: #{$val}px !important;
  }
  @each $bp-name, $bp-value in $breakpoints {
    .h-#{$bp-name}-#{$val} {
      @media (min-width: #{$bp-value}) {
        height: #{$val}px !important;
      }
    }
  }
}
