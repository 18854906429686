/* You can add global styles to this file, and also import other style files */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.primary-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  outline: none;
  color: white;
  background-color: var(--button-color);
  padding: 10px 16px;
  text-transform: uppercase;
  cursor: pointer;
  gap: 5px;
  &:hover {
    border: 1px solid var(--button-hover-color);
    background-color: var(--button-hover-color);
    box-shadow: #63636333 0 2px 8px;
  }
}

.primary-btn:disabled {
  cursor: not-allowed;
  background-color: #ccc;
  border-color: #ccc;
}

.secondary-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--button-color);
  border-radius: 5px;
  outline: none;
  color: var(--button-color);
  background-color: white;
  padding: 10px 16px;
  text-transform: uppercase;
  cursor: pointer;
  gap: 5px;
  &:hover {
    border: 1px solid var(--button-hover-color);
    color: var(--button-hover-color);
    box-shadow: #63636333 0 2px 8px;
  }
}

.disable-btn {
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  outline: none !important;
  color: white !important;
  background-color: lightgrey !important;
}

.card {
  box-shadow: #63636333 0 2px 8px;
  border-radius: 5px;
  background-color: white;
}

.spinner-all-screen-container {
  height: calc(100vh - 270px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.border-none {
  border: none;

  &:hover {
    border: none;
  }
}

.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.ag-theme-alpine {
  --ag-borders: none;
}

//flex classes
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.justify-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: start;
}
.flex-grow {
  flex-grow: 1;
}
